import React, { Component } from 'react';
import { ContactUs } from './components/contactUs';
import {ContactSubmitted} from './components/contactSubmitted';

interface ContactState {
    /** If form has been sent */
    formSent: boolean
}

class Contact extends Component<Record<string, never>, ContactState> {
    constructor(props: Record<string, never>) {
        super(props);
        this.state = {
            formSent: false
        };
    }


    render() {
        return (
            <div>
                <h2>ICH FREUE MICH AUF IHRE NACHRICHT</h2>
                {this.state.formSent ? <ContactSubmitted /> :
                    <ContactUs onSent={() => this.setState({ formSent: true })} />}
            </div>
        );
    }
}

export default Contact;
