import React, { Component } from 'react';
import ExpertiseContainer from './components/expertiseContainer';
import {NavLink} from 'react-router-dom';

class Expertise extends Component {
    render() {
        return (
            <div>
                <h2>EXPERTISE</h2>
                <br/>
                <ExpertiseContainer title={'Frontend-Entwicklung'} initialOpen>
                    <p>
                        Ich verfüge über mehrhährige Expertise in den folgenden Technologien, welche es mir erlauben moderne und wartbare User Interfaces zu erstellen:

                        <ul>
                            <li>Sprachenexperte für TypeScript</li>
                            <li>Test-Driven-Development</li>
                            <li>React</li>
                            <li>Vue</li>
                            <li>Vuex</li>
                            <li>Redux</li>
                            <li>Nuxt</li>
                            <li>Storybook</li>
                            <li>Vuetify</li>
                            <li>Material UI</li>
                            <li>Pinia Store</li>
                            <li>Figma</li>
                            <li>Chromatic</li>
                            <li>Zusammenarbeit mit Design-Teams</li>
                        </ul>

                        Eine Impression meiner Arbeiten im Open Source Bereich finden Sie auf <a href="https://github.com/foxriver76">GitHub</a>.
                    </p>
                </ExpertiseContainer>
                <ExpertiseContainer title={'Backend-Entwicklung'}>
                    <p>
                        Durch meine jahrelange Expertise im Bereich Node.js und TypeScript biete ich
                        individuelle Backend-Lösungen an. Meine Schwerpunkte liegen hier neben Node.js und
                        TypeScript in den folgenden Bereichen:
                        <ul>
                            <li>Sprachenexperte für TypeScript</li>
                            <li>Test-Driven-Development</li>
                            <li>AWS (Cognito, IAM, DynamoDB, S3, OIDC und mehr)</li>
                            <li>CI-Automatisierungen via GitHub Actions</li>
                            <li>Redis DB</li>
                            <li>Nuxt</li>
                            <li>Komplexe CLI-Anwendungen mit yargs</li>
                            <li>Anbindung von APIs</li>
                            <li>JSON Schema</li>
                            <li>Entwicklung von REST-APIs (Express)</li>
                            <li>Dokumentation in Markdown/LaTeX</li>
                            <li>Linux</li>
                            <li>Docker</li>
                            <li>Virtualisierung (Proxmox)</li>
                        </ul>

                        Eine Impression meiner Arbeiten im Open Source Bereich finden Sie auf <a href="https://github.com/foxriver76">GitHub</a>.
                    </p>
                </ExpertiseContainer>
                <ExpertiseContainer title={'App-Entwicklung'}>
                    <p>
                        Ich habe mehrere Jahre Erfahrung in der Cross-Platform App-Entwicklung mit React Native, insbesondere mit dem Expo Framework.
                        Ich entwickle maßgeschneiderte Submodule in Kotlin und Swift, um spezifische Anforderungen zu erfüllen, für welche es derzeit noch keine React Native Module gibt.<br/><br/>
                        Meine Projekte umfassen sowohl einfache Anwendungen wie den authentifizierten Zugriff auf einen WebView als auch komplexe Anwendungen mittels Kamerazugriff, Geofencing, Matter Commissioning und vieles mehr.
                    </p>
                </ExpertiseContainer>
                <ExpertiseContainer title={'Internet of Things & Smart Home'}>
                    <p>
                        Ehrenamtlich bin ich einer der Core-Entwickler der Open Source Middleware Platform ioBroker.
                        Da ich die ioBroker Codebase sowie das Ökosystem kenne wie kein Anderer, bin ich der perfekte
                        Ansprechpartner
                        wenn eine Firma ihr Produkt oder ihren Service in ioBroker integrieren möchte oder kritische
                        Bugs sowie Features in einem Adapter bearbeitet haben möchte.

                        <br/>
                        <br/>

                        Neben der Core Entwicklung konnte ich auch andere Projekte im IoT-Bereich umsetzen in welchem
                        ioBroker als Middleware Plattform genutzt wurde.
                        So ist es problemlos möglich, große Sensornetzwerke aufzubauen und die Daten zentral zu
                        visualisieren und zu verarbeiten. So können z. B.
                        Sensordaten zentral aggregiert werden und analysiert werden, dezentrale Stromverbräuche für
                        Abrechnungen erfasst werden oder komplexe Prüfstände gebaut werden.

                        <br/>
                        <br/>

                        Auch die Programmierung und Einbindung von Miktrokontrrollern für dezentrale Sensorik mittels Micropython und der Weiterleitung der Daten über MQTT
                        an eine zentrale Steuerung sind umsetzbar.
                    </p>
                </ExpertiseContainer>
                <ExpertiseContainer title={'Datenanalyse und maschinelles Lernen'}>
                    <p>
                        Ich habe promoviert im Bereich des maschinellen Lernens.<br/>
                        Mein Themengebiet umfasst die Vorverarbeitung sowie Analye von hochfrequenten Datenströmen, wie
                        sie im IoT-Kontext oft zu finden sind.<br/>
                        Meine Veröffentlichungen finden Sie auf <a href="https://dblp.org/pid/239/7317.html">DBLP</a>.

                        <br/>
                        <br/>

                        Gerne bringe ich dieses Wissen mit meinen anderen Aufgabengebieten zusammen um z. B. neben
                        der Zusammenführung der Daten von dezentralen Sensoren, ein Analysekonzept zu Aufgabenstellungen
                        des maschinellen Lernens mit Ihnen zu erarbeiten und die Implementierung der Algorithmik zu unterstützen.
                        Dies kann interessant sein für Predictive Maintenance Aufgabenstellungen wie der Abnutzungserkennung von Schleifscheiben oder der Erkennung von Rüstphasen.
                    </p>
                </ExpertiseContainer>

                <p>Bei Interesse <NavLink to="/contact">kontaktieren</NavLink> Sie mich gerne.</p>
            </div>
        );
    }
}

export default Expertise;
