import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <div className="text-container">
                <h2>WILLKOMMEN</h2>
                <p>
                    Als erfahrener Fullstack-Entwickler bringe ich einen großen Erfahrungsschatz zur Lösung
                    verschiedenster Problemstellungen mit.<br/> Ich habe professionelle Erfahrung mit Frontend-Technologien
                    wie <strong>React</strong>, <strong>Vue</strong> sowie <strong>Storybook</strong>.<br/>
                    Für Plattform-übergreifende App Entwicklung nutze ich <strong>React Native</strong> und <strong>Expo</strong>.<br/>
                    Im Backend bin ich erfahren mit <strong>Node.js</strong> und <strong>AWS</strong>.<br/>
                    Außerdem bin ich ein Sprachexperte für <strong>TypeScript</strong> und baue gerne IoT-Lösungen.<br/>
                    Als Core Entwickler der Smart Home Middleware Plattform ioBroker verfüge ich über ein starkes Domänenwissen für
                    die Vernetzung von IoT-Geräten.
                </p>
            </div>
        );
    }
}

export default Home;
