import React from 'react';
import {Collapse, Card, CardContent, CardHeader, IconButton} from '@mui/material';
import './../style/expertiseContainer.css';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';

interface ExpertiseContainerProps {
    /**
     * The title
     */
    title: string;
    /**
     * The card content
     */
    children: React.ReactNode;
    /**
     * If initially open
     */
    initialOpen?: boolean;
}

interface ExpertiseContainerState {
    /**
     * If collapse is open
     */
    open: boolean;
}

export default class ExpertiseContainer extends React.Component<ExpertiseContainerProps, ExpertiseContainerState> {
    constructor(props: ExpertiseContainerProps) {
        super(props);

        this.state = {
            open: !!this.props.initialOpen
        };
    }

    /**
     * Render the component
     */
    render(): React.ReactNode {
        return <Card className={'expertise-container'}>
            <CardHeader onClick={() => this.setState({ open: !this.state.open })} title={this.props.title} className={'expertise-container-header'}
                action={
                    <div style={{ display: 'flex' }}>
                        <IconButton
                            onClick={() => {
                                this.setState({
                                    open: !this.state.open
                                });
                            }}
                            aria-label="expand"
                            size="small"
                        >
                            {this.state.open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </div>
                }
            />
            <Collapse in={this.state.open}>
                <CardContent>{this.props.children}</CardContent>
            </Collapse>
        </Card>;
    }
}