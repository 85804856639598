import React, { Component } from 'react';
import emailjs from '@emailjs/browser';
import './../style/contactUs.css';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Checkbox } from '@mui/material';
import {NavLink } from 'react-router-dom';
import {EMAILJS_PUBLIC_KEY, EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID} from '../lib/constants';

interface ContactUsState {
    /** if form is valid */
    valid: boolean;
    /** Indicator if mail is in sending status */
    isLoading: boolean;
}

interface ContactUsProps {
    /** function called on submit */
    onSent: () => void;
}

export class ContactUs extends Component<ContactUsProps, ContactUsState> {
    private name = '';
    private mail = '';
    private phone = '';
    private message = '';
    private privacyAccepted = false;

    constructor(props: ContactUsProps) {
        super(props);

        this.state = {
            valid: false,
            isLoading: false
        };
    }

    render() {
        return (
            <form onSubmit={this.sendEmail} className={'form-container'}>
                <TextField
                    onChange={event => {
                        this.name = event.target.value;
                        this.validateForm();
                    }}
                    label={'Name'}
                    required={true}
                    className={'config-input'}
                    type="text"
                    name="user_name"
                />
                <TextField
                    onChange={event => {
                        this.mail = event.target.value;
                        this.validateForm();
                    }}
                    label={'E-Mail'}
                    required={true}
                    error={!!this.mail && !this.isMailValid()}
                    className={'config-input'}
                    type="email"
                    name="user_email"
                />
                <TextField
                    onChange={event => (this.phone = event.target.value)}
                    label={'Telefon'}
                    className={'config-input'}
                    name="user_phone"
                />
                <TextField
                    onChange={event => {
                        this.message = event.target.value;
                        this.validateForm();
                    }}
                    label={'Ihre Nachricht'}
                    multiline={true}
                    required={true}
                    minRows={'10'}
                    maxRows={'10'}
                    className={'config-input'}
                    name="message"
                />

                <div className={'submit-button-container'}>
                    <div className={'form-checkbox-container'}>
                        <Checkbox
                            onChange={(event, checked) => {
                                this.privacyAccepted = checked;
                                this.validateForm();
                            }}
                        />
                        <p>
                            <NavLink to="/privacy" target={'_blank'}>Datenschutzerklärung akzeptieren</NavLink>
                        </p>
                    </div>
                    <LoadingButton
                        id={'submit-button'}
                        disabled={!this.state.valid}
                        onClick={async () => {
                            this.setState({ isLoading: true });
                            await this.sendEmail();
                            this.props.onSent();
                        }}
                        variant={'contained'}
                        type={'button'}
                        color={'primary'}
                        loading={this.state.isLoading}
                    >
                        Senden
                    </LoadingButton>
                </div>
            </form>
        );
    }

    /**
     * Send Email from form input
     */
    async sendEmail(): Promise<void> {
        const res = {
            name: this.name,
            phone: this.phone,
            mail: this.mail,
            message: this.message
        };

        try {
            await emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, res, EMAILJS_PUBLIC_KEY);
        } catch (e: any) {
            console.error(`Could not send mail: ${e.message}`);
        }
    }

    /**
     * Validates form to activate Send Button
     */
    validateForm(): void {
        if (this.privacyAccepted && this.name && this.message && this.isMailValid()) {
            this.setState({ valid: true });
            return;
        }

        this.setState({ valid: false });
    }

    /**
     * Validate the Mail
     */
    isMailValid(): boolean {
        return (
            this.mail?.includes('@') &&
            this.mail?.includes('.') &&
            !this.mail.endsWith('.') &&
            this.mail.lastIndexOf('@') < this.mail.lastIndexOf('.')
        );
    }
}