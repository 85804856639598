import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';

class Privacy extends Component {
    render() {
        return (
            <div>
                <h1>Datenschutzerklärung</h1>
                <h2 id="m1870">Einleitung</h2>
                <p>
                    Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer
                    personenbezogenen Daten (nachfolgend auch kurz als &quot;Daten&quot; bezeichnet) wir zu welchen
                    Zwecken und in welchem Umfang im Rahmen der Bereitstellung unserer Applikation verarbeiten.
                </p>
                <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
                <p>Stand: 14. März 2022</p>
                <h2>Inhaltsübersicht</h2>
                <ul className="index">
                    <li>
                        <Link className="index-link" to="#m1870">
                            Einleitung
                        </Link>
                    </li>
                    <li>
                        <Link className="index-link" to="#m3">
                            Verantwortlicher
                        </Link>
                    </li>
                    <li>
                        <Link className="index-link" to="#mOverview">
                            Übersicht der Verarbeitungen
                        </Link>
                    </li>
                    <li>
                        <Link className="index-link" to="#m13">
                            Maßgebliche Rechtsgrundlagen
                        </Link>
                    </li>
                    <li>
                        <Link className="index-link" to="#m27">
                            Sicherheitsmaßnahmen
                        </Link>
                    </li>
                    <li>
                        <Link className="index-link" to="#m225">
                            Bereitstellung des Onlineangebotes und Webhosting
                        </Link>
                    </li>
                    <li>
                        <Link className="index-link" to="#m182">
                            Kontakt- und Anfragenverwaltung
                        </Link>
                    </li>
                </ul>
                <h2 id="m3">Verantwortlicher</h2>
                <p>
                    {this.props.owner}
                    <br />
                    {this.props.street}
                    <br />
                    {this.props.city}, Deutschland
                </p>
                E-Mail-Adresse:
                <p>info@{this.props.domain}</p>
                <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
                <p>
                    Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung
                    zusammen und verweist auf die betroffenen Personen.
                </p>
                <h3>Arten der verarbeiteten Daten</h3>
                <ul>
                    <li>Bestandsdaten.</li>
                    <li>Kontaktdaten.</li>
                    <li>Inhaltsdaten.</li>
                    <li>Nutzungsdaten.</li>
                    <li>Meta-/Kommunikationsdaten.</li>
                </ul>
                <h3>Kategorien betroffener Personen</h3>
                <ul>
                    <li>Kommunikationspartner.</li>
                    <li>Nutzer.</li>
                </ul>
                <h3>Zwecke der Verarbeitung</h3>
                <ul>
                    <li>Erbringung vertraglicher Leistungen und Kundenservice.</li>
                    <li>Kontaktanfragen und Kommunikation.</li>
                    <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                </ul>
                <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
                <p>
                    Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir
                    personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der
                    DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten
                    ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der
                    Datenschutzerklärung mit.
                </p>
                <ul>
                    <li>
                        <strong>
                            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO)
                        </strong>{' '}
                        - Die - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die
                        betroffene betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich,
                        die auf Anfrage der betroffenen Person erfolgen.
                    </li>
                    <li>
                        <strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)</strong> - Die Verarbeitung
                        ist zur ist zur ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der der
                        Verantwortliche unterliegt.
                    </li>
                    <li>
                        <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die Verarbeitung
                        ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten
                        erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen
                        Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
                    </li>
                </ul>
                <p>
                    Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen
                    zum zum zum Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor
                    Missbrauch personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das
                    BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum
                    Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur Verarbeitung
                    für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall
                    einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für Zwecke des
                    Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung, Durchführung
                    oder Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner
                    können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.
                </p>
                <h2 id="m27">Sicherheitsmaßnahmen</h2>
                <p>
                    Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik,
                    der der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der
                    Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der
                    Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und organisatorische
                    Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.
                </p>
                <p>
                    Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
                    Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als
                    auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und
                    ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
                    Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
                    gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
                    Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
                    Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
                </p>
                <p>
                    SSL-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu schützen,
                    nutzen wir eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix
                    https:// in der Adresszeile Ihres Browsers.
                </p>
                <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
                <p>
                    Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die Leistungen von
                    einem oder mehreren Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen verwalteten
                    Servern) das Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir Infrastruktur- und
                    Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste sowie
                    Sicherheitsleistungen und technische Wartungsleistungen in Anspruch nehmen.
                </p>
                <p>
                    Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die Nutzer
                    unseres Onlineangebotes betreffenden Angaben gehören, die im Rahmen der Nutzung und der
                    Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte
                    von Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres Onlineangebotes oder
                    von Webseiten getätigten Eingaben.
                </p>
                <ul className="m-elements">
                    <li>
                        <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Eingaben in Onlineformularen);
                        Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
                        Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                    </li>
                    <li>
                        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                        Onlinediensten).
                    </li>
                    <li>
                        <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
                        Nutzerfreundlichkeit.
                    </li>
                    <li>
                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                    </li>
                </ul>
                <p>
                    <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
                </p>
                <ul className="m-elements">
                    <li>
                        <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Wir selbst (bzw. unser
                        Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server (sogenannte Serverlogfiles).
                        Zu den Serverlogfiles können die Adresse und Name der abgerufenen Webseiten und Dateien, Datum
                        und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp
                        nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
                        Regelfall IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles können zum einen
                        zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
                        (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum
                        anderen, um die Auslastung der Server und ihre Stabilität
                        <strong>Löschung von Daten:</strong> Logfile-Informationen werden für die Dauer von maximal 30
                        Tagen gespeichert und danach gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu
                        Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der
                        Löschung ausgenommen.
                    </li>
                </ul>
                <h2 id="m182">Kontakt- und Anfragenverwaltung</h2>
                <p>
                    Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via soziale Medien)
                    sowie im sowie im Rahmen bestehender Nutzer- und Geschäftsbeziehungen werden die Angaben der
                    anfragenden Personen verarbeitet soweit dies zur Beantwortung der Kontaktanfragen und etwaiger
                    angefragter Maßnahmen erforderlich ist.
                </p>
                <p>
                    Die Beantwortung der Kontaktanfragen sowie die Verwaltung von Kontakt- und Anfragedaten im Rahmen
                    von von von vertraglichen oder vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer
                    vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen Anfragen und im Übrigen auf
                    Grundlage der berechtigten Interessen an der Beantwortung der Anfragen und Pflege von Nutzer- bzw.
                    Geschäftsbeziehungen.
                </p>
                <ul className="m-elements">
                    <li>
                        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten
                        (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen).
                    </li>
                    <li>
                        <strong>Betroffene Personen:</strong> Kommunikationspartner.
                    </li>
                    <li>
                        <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Erbringung
                        vertraglicher Leistungen und Kundenservice.
                    </li>
                    <li>
                        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1
                        S. 1 S. 1 lit. b. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO); Rechtliche
                        Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO).
                    </li>
                </ul>
                <p>
                    <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
                </p>
                <ul className="m-elements">
                    <li>
                        <strong>Kontaktformular: </strong>Wenn Nutzer über unser Kontaktformular, E-Mail oder andere
                        Kommunikationswege mit uns in Kontakt treten, verarbeiten wir die uns in diesem Zusammenhang
                        mitgeteilten Daten zur Bearbeitung des mitgeteilten Anliegens. Zu diesem Zweck verarbeiten wir
                        personenbezogene Daten im Rahmen vorvertraglicher und vertraglicher Geschäftsbeziehungen, soweit
                        dies zu deren Erfüllung erforderlich ist und im Übrigen auf Grundlage unserer berechtigten
                        Interessen sowie der Interessen der Kommunikationspartner an der Beantwortung der Anliegen und
                        unserer gesetzlichen Aufbewahrungspflichten.
                    </li>
                </ul>
                <p className="seal">
                    <a
                        href="https://datenschutz-generator.de/"
                        title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                    >
                        Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke
                    </a>
                </p>
            </div>
        );
    }
}

Privacy.propTypes = {
    owner: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired
};

export default Privacy;
