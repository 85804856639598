import React, { Component } from 'react';
import {Route, NavLink, HashRouter, Routes} from 'react-router-dom';
import Home from './home';
import Contact from './contact';
import Expertise from './expertise';
import Imprint from './imprint';
import Privacy from './privacy';
import { PHONE, DOMAIN, CITY, OWNER, STREET } from './lib/constants';
import { theme } from './tools/theme';
import {Drawer, Link, ThemeProvider} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

interface MainState {
    /** If the drawer is opened */
    drawer: boolean;
}

class Main extends Component<Record<string, never>, MainState> {
    constructor(props: Record<string, never>) {
        super(props);

        this.state = {
            drawer: false
        };
    }

    /**
     * Render the Drawer
     */
    renderDrawer(): React.ReactElement {
        return <Drawer open={this.state.drawer} onClose={() => this.setState({drawer: false})} className={'drawer'} sx={{
            '& .MuiPaper-root': {
                width: 200,
                opacity: 0.95
            }
        }}>
            <CloseIcon onClick={() => this.setState({drawer: false})} className={'close-icon'} />
            <NavLink to="/" onClick={() => this.setState({drawer: false})}>Startseite</NavLink>
            <NavLink to="/expertise" onClick={() => this.setState({drawer: false})}>Expertise</NavLink>
            <Link href="/Moritz_Heusinger_CV.pdf" target={'_blank'} onClick={() => this.setState({drawer: false})}>Vita</Link>
            <NavLink to="/contact" onClick={() => this.setState({drawer: false})}>Kontakt</NavLink>
        </Drawer>;
    }

    /**
     * Render the main application
     */
    render(): React.ReactElement {
        return (
            <ThemeProvider theme={theme}>
                <HashRouter>
                    {this.renderDrawer()}
                    <div>
                        <div className={'headline-container'}>
                            <div className={'portrait-container'}>
                                <img width="100%" alt="portrait" className="portrait" src='./portrait.jpeg' />
                            </div>
                            <h1 className={'headline'}>MORITZ HEUSINGER, PhD <br /> Software Entwickler und IoT-Enthusiast</h1>
                        </div>
                        <ul className="header">
                            <MenuIcon className={'menu-icon'} onClick={() => this.setState({drawer: true})} />
                            <li>
                                <NavLink to="/">Startseite</NavLink>
                            </li>
                            <li>
                                <NavLink to="/expertise">Expertise</NavLink>
                            </li>
                            <li>
                                <Link href="/Moritz_Heusinger_CV.pdf" target={'_blank'}>Vita</Link>
                            </li>
                            <li>
                                <NavLink to="/contact">Kontakt</NavLink>
                            </li>
                        </ul>
                        <div className="content">
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/expertise" element={<Expertise />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route
                                    path="/imprint"
                                    element={
                                        <Imprint domain={DOMAIN} phone={PHONE} street={STREET} owner={OWNER} city={CITY} />
                                    }
                                />
                                <Route
                                    path="/privacy"
                                    element={<Privacy domain={DOMAIN} street={STREET} owner={OWNER} city={CITY} />}
                                />
                            </Routes>
                        </div>
                    </div>
                    <div className="footer">
                        <p>
                            <NavLink to="/imprint">Impressum</NavLink> | <NavLink to="/privacy">Datenschutz</NavLink>
                            <br />
                            &copy; Moritz Heusinger {new Date().getFullYear()}
                        </p>
                        <div className={'contact-container'}>
                            <Link href={'https://www.linkedin.com/in/moritz-heusinger-52898721b/'} target={'_blank'}><LinkedInIcon className={'contact-icon'} /></Link>
                            <Link href={'https://github.com/foxriver76'} target={'_blank'}><GitHubIcon className={'contact-icon'} /></Link>
                        </div>
                    </div>
                </HashRouter>
            </ThemeProvider>
        );
    }
}

export default Main;
